import { useParams, Link } from "react-router-dom";
import { useEffect, useState } from "react";

import { Card, Loader, PageHeader } from "../../../../components/layout";
import { getVerboseStoreName } from "../../../../constants/auth";
import { billingPaths } from "../../../../routes/billing/paths";
import Spinner from "../../../../components/layout/Spinner";
import { decimalPrice } from "../../../../utils/numbers";
import { Plan } from "../../../../requests/plans/types";
import { URLParams } from "../../../../constants";
import usePlans from "../../../../requests/plans";
import Styles from "./styles";



function PlanDetail() {
  const { planId } = useParams<URLParams>();
  const { getPlan } = usePlans();
  const [plan, setPlan] = useState<Plan>();


  useEffect(() => {
    if (!planId) return;
    getPlan(planId)
      .then((plan) => {
        setPlan(plan)
      }).catch(() => null);
  }, [planId])



  return (
    <Styles className="root-content">
      {/* Header */}
      <PageHeader
        title={plan ? (
          plan.name
        ) : (
          <Spinner />
        )}
      />

      {/* Main Content */}
      <div className="page-wrapper">
        {!plan ? (
          <Loader />
        ) : (
          <>
            {/* Plan Details */}
            <Card className="plan-detail flex column">
              <div className="head">
                <h3 className="plan-region">
                  {(plan.region === "INTL") ? (
                    "International"
                  ) : (
                    "United Kingdom"
                  )}
                </h3>
                <h1 className="name">
                  {plan.name} Plan
                </h1>
              </div>
              <div className="content">
                <div className="title">
                  {plan.featureTitle}
                </div>
                <ul className="features flex column">
                  {plan.planFeatures.map(feature =>
                    <li className="feature" key={feature.id}>
                      {feature.description}
                    </li>
                  )}
                </ul>
              </div>
              <Link
                to={billingPaths.editPlan(plan.id)}
                className="button mt-auto"
              >
                Edit Plan's Features
              </Link>
            </Card>

            {/* Plan Prices */}
            <div className="plan-prices">
              {plan.planPrices.map((planPrice) =>
                <Card className="plan-price" key={planPrice.id}>
                  <div className="card-title flex justify-between">
                    <div>
                      {getVerboseStoreName(planPrice.store)}
                    </div>
                    <div>ID: {planPrice.id}</div>
                  </div>
                  <div className="main-value flex">
                    {plan.region === "UK" ? "£" : "$"}
                    {decimalPrice(planPrice.amount)}
                    <div className="interval">
                      {`/ ${planPrice.interval}`}
                    </div>
                  </div>
                </Card>
              )}
            </div>
          </>
        )}
      </div>
    </Styles>
  );
}

export default PlanDetail;