import styled from "styled-components";

import checkmark from
  "../../../../assets/vector-images/generic/check-green.svg";
import { colors, resolutions } from "../../../../styles";



export const planStyles = `
  padding: 2rem 2.5rem;
  max-width: 500px;
  @media(max-width: ${resolutions.tablet}) {
    padding: 1rem;
  }
  ${'' /* Plan Name and Price */}
  .head {
    .name {
      font-size: 20px;
      font-weight: 600;
    }
    .price {
      margin-top: 0.5rem;
      gap: 4px;
      font-size: 20px;
      align-items: flex-end;
      font-weight: 700;
      .interval {
        font-size: 1rem;
      }
    }
  }
  ${'' /* Plan Description */}
  .content {
    margin: 1rem 0;
    @media(max-width: ${resolutions.tablet}) {
      margin: 1rem 0;
      margin-top: 0.5rem;
    }
    .title {
      margin-bottom: 1rem;
      font-size: 0.9rem;
    }
    .features {
      gap: 12px;
      .feature {
        position: relative;
        padding-left: 20px;
        font-size: 0.8rem;
        color: ${colors.grey6};
        &::before {
          content: "";
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          background-image: url(${checkmark});
          height: 14px;
          width: 14px;
        }
      }
    }
  }
  .button.choose-plan {
    margin-top: auto;
    border-radius: 12px;
  }
`;

const Styles = styled.div`
  .page-wrapper.plans {
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(4, 1fr);
    @media(max-width: ${resolutions.miniDesktop}) {
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    }
    .plan {
      ${planStyles}
    }
  }
`;

export default Styles;