import { Link } from "react-router-dom";
import moment from "moment";

import { ModelDetailData } from "../../../components/layout/ModelDetail";
import { accountPaths } from "../../../routes/accounts/paths";
import { PrettyJson } from "../../../components/layout";
import { Email } from "../../../requests/emails/types";



export const useEmailDetailUtils = () => {
  const buildEmailData = (email: Email) => {
    const {
      email: username, templateName, createdAt, sentBy, templateId,
      status, errorResponse, receivedBy, scheduledAt
    } = email;
    const modelData: ModelDetailData[] = [
      {
        name: "Email",
        description: username
      },
      {
        name: "Status",
        description: (
          <div className={`status ${status}`}>
            {email.status}
          </div>
        )
      },
      {
        name: "Template Name",
        description: templateName
      },
      {
        name: "Template ID",
        description: (
          <a
            target="_blank"
            rel="noopener noreferrer"
            className="link underline"
            href={`https://app.mailersend.com/templates/${templateId}/edit`}
          >
            {email.templateId}
          </a>
        )
      },
      {
        name: "Created At",
        description: (
          <div className="grey-text">
            {moment(createdAt).isValid()
              ? moment(createdAt).format("Do MMMM, YYYY - hh:mma")
              : "-"
            }
          </div>
        )
      },
      {
        name: "Created By",
        description: (
          <div className="grey-text">
            {sentBy ? (
              `${sentBy.firstName} ${sentBy.lastName}`
            ) : "-"}
          </div>
        )
      },
      {
        name: "Scheduled At",
        description: (
          <div className="grey-text">
            {moment(scheduledAt).isValid()
              ? moment(scheduledAt).format("Do MMMM, YYYY - hh:mma")
              : "-"
            }
          </div>
        )
      },
    ]
    // Optional User Object
    if (receivedBy) {
      modelData.push(
        {
          name: "User",
          description: (
            <Link
              className="link underline"
              to={accountPaths.userDetail(receivedBy?.id || "")}
            >
              {receivedBy ? (
                `${receivedBy.username}`
              ) : "-"}
            </Link>
          )
        },
      )
    }
    // Optional Error Response
    if (
      (errorResponse) &&
      Object.keys(email.errorResponse).length > 0
    ) {
      modelData.push(
        {
          name: "Error",
          description: (
            <PrettyJson
              json={email.errorResponse || ""}
              title="Mailersend Error"
            />
          )
        }
      )
    }

    return modelData;
  }


  return {
    buildEmailData
  }
}