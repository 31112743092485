import React, { useEffect, useRef } from "react";

import img_preview from "../../../assets/vector-images/panel/image.svg";
import useFileFieldUtils from "./utils";
import Styles from "./styles";



interface Props extends Omit<
  React.InputHTMLAttributes<HTMLInputElement>, "onChange"
> {
  name: string;
  initialPreview?: string;
  onChange: (name: string, file: File | FileList) => void;
  maxFileSize?: number; // in bytes
}

function FileField(props: Props) {
  const {
    name, multiple, maxFileSize, initialPreview, onChange,
    placeholder = "Drop file or click to browse",
    ...inputProps
  } = props;
  const fileRef = useRef<HTMLInputElement>(null);
  const {
    dragClass, fileDetails,
    handleFileChange, handleDragOver, handleDragExit, handleDrop
  } = useFileFieldUtils({ name, onChange, multiple });


  /********************************
   * **Open file selection window**
   */
  const selectFile = () => {
    fileRef.current?.click();
  }


  useEffect(() => {
    return () => {
      // Revoke the object URLs to free up memory
      fileDetails.forEach((fileDetail) =>
        URL.revokeObjectURL(fileDetail.icon)
      );
    }
  }, [fileDetails]);



  return (
    <Styles
      className={`file-field ${dragClass}`}
      onClick={selectFile}
      onDrop={handleDrop}
      onDragOver={handleDragOver}
      onDragLeave={handleDragExit}
    >
      <input
        {...inputProps}
        type="file"
        id={name}
        name={name}
        ref={fileRef}
        multiple={multiple}
        placeholder={placeholder}
        onChange={handleFileChange}
      />
      <div className="previews flex wrap center">
        {fileDetails.length ? (fileDetails.map((fileDetail, index) => (
          <figure key={index} className="flex column center">
            <img alt="" className="preview" src={fileDetail.icon} />
            <figcaption>
              {fileDetail.title}
            </figcaption>
          </figure>
        ))) : initialPreview ? (
          <figure className="flex column center">
            <img alt="" className="preview" src={initialPreview} />
            <figcaption>
              {initialPreview.split("/").pop()}
            </figcaption>
          </figure>
        ) : (
          <figure className="placeholder flex column center">
            <img src={img_preview} alt="" />
            <figcaption>{placeholder}</figcaption>
          </figure>
        )}
      </div>
      <div className="actions flex column center">
        <div className="link underline">
          {multiple ? (
            "Select Files"
          ) : fileDetails.length || initialPreview ? (
            "Change File"
          ) : (
            "Select a File"
          )}
        </div>
      </div>
    </Styles>
  );
};

export default FileField;